<template>
    <page-header-wrapper>
        <template slot="extra">
            <a-button type="primary" @click="add" v-action:add>新增</a-button>
        </template>
        <a-card :bordered="false">
            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto">
                <span slot="state" slot-scope="record">
					<a-tag color="#87d068" v-if="record.status==1">已显示</a-tag>
					<a-tag color="rgb(156, 154, 154)" v-else-if="record.status==2">已隐藏</a-tag>
                </span>
				<span slot="img" slot-scope="img_url">
					<img :src="prefix+img_url" alt="" style="max-width: 200px;">
				</span>
                <span slot="time" slot-scope="create_time">
                    {{create_time | time}}
                </span>
                <span slot="operation">
                    --
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <a @click="handleEdit(record)" v-action:edit>编辑</a>
                        <a-divider type="vertical" />
                        <a @click="handleShow(record.id,2)" v-if="record.status==1" v-action:setStatus>设置隐藏</a>
						<a @click="handleShow(record.id,1)" v-else-if="record.status==2" v-action:setStatus>设置显示</a>
                    </template>
                </span>
            </s-table>
        </a-card>
		
		<!-- 新增 -->
		<a-modal
			class="modal_foot_center"
		    :title="form.id?'编辑Banner':'新增Banner'"
		    :visible="visible"
		    :confirm-loading="confirmLoading"
		    @ok="handleOk"
		    @cancel="handleCancel">
		    <div>
				<div class="bannerItem">
					<div class="bannerItem_l"><i class="tips">*</i>Banner图片</div>
					<div class="bannerItem_r">
							<a-upload
								name="file"
								:multiple="false"
								:action="imgUpload"
                                :data="dataObj"
                                :headers="headers"
								@change="handleChange" v-if="!form.cover">
							    <div class="bannerUpload">
								   <div><a-icon type="plus" style="color:#999;font-size: 30px;" /></div>
								   <div>上传</div>
							    </div>
							</a-upload>
							<div v-else>
								<div class="preview">
									<img :src="prefix+form.cover" alt="">
									<a-icon type="close-circle" class="icon-delete" v-show="form.cover" @click="deleteImg" />
								</div>
							</div>
						<div class="bannerItem_tip">支持png、jpg、gif格式图片，建议尺寸750*130px;</div>
					</div>
				</div>
				<div class="bannerItem">
					<div class="bannerItem_l">链接类型</div>
					<div class="bannerItem_r">
						<a-radio-group v-model="form.type">
						    <a-radio :value="1">网址</a-radio>
						    <a-radio :value="2">小程序路径</a-radio>
						</a-radio-group>
					</div>
				</div>
				<div class="bannerItem">
					<div class="bannerItem_l">跳转链接</div>
					<div class="bannerItem_r">
						<a-input placeholder="请输入" v-model="form.url"/>
					</div>
				</div>
			</div>
		</a-modal>
		<!-- end -->
    </page-header-wrapper>
</template>

<script>
    import storage from 'store';
    import { ACCESS_TOKEN } from '@/store/mutation-types';
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue';
	import { imgUpload,prefix,decrypt } from '@/utils'
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getBannerList,
		editBanner,
		setStatus
    } from '@/api/article'
    const columns = [
        {
            title: '状态',
            scopedSlots: {
                customRender: 'state'
            },
            align:'center',
            width:'90px'
        },
        {
            title: '图片',
            dataIndex: 'img_url',
			scopedSlots: {
			    customRender: 'img'
			},
            align:'center'
        },
        {
            title: '操作人',
            dataIndex: '',
            align:'center',
            scopedSlots: {
                customRender: 'operation'
            },
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            scopedSlots: {
                customRender: 'time'
            },
            align:'center',
			sorter:true
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]

    const statusMap = {
        1: {
            status: 'success',
            text: '已显示'
        },
        2: {
            status: 'error',
            text: '已隐藏'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                // 查询参数
                queryParam: {
                   
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return getBannerList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                
				confirmLoading:false,
				visible:false,
				form:{
					id:'',
					cover:'',
					url:'',
					type:2
				},
				imgUpload:imgUpload,
				prefix:prefix,
                dataObj:{
                   
				},
                headers:{
                    token:storage.get(ACCESS_TOKEN)
                },
            }
        },
		created(){
			
		},
        methods: {
            add(){   //新增
				this.form.id = ''
				this.form.cover = ''
				this.form.url = ''
				this.form.type = 2
                this.visible = true
            },
            handleEdit(data){  //编辑
				this.form.id = data.id
				this.form.cover = data.img_url
				this.form.url = data.path
				this.form.type = Number(data.type)
				this.visible = true
            },
            reset() { //重置
                this.queryParam.time = null
                this.queryParam.status = '0'
                this.queryParam.keyword = ''
                this.$refs.table.refresh(true)
            },
			handleShow(id,status){
				let params = {
					id:id,
					status:status
				}
				setStatus(params).then(res =>{
					if(res.code == 200){
						this.$message.success('设置成功')
						this.$refs.table.refresh(true)
					}
				})
			},
			handleOk(){
				let id = this.form.id;
				let imgUrl = this.form.cover
				let path = this.form.url
				
				if(!imgUrl){
					this.$message.info('请上传图片')
					return false
				}
				
				let params = {
					id:id,
					imgUrl:imgUrl,
					path:path,
					type:this.form.type
				}
				editBanner(params).then(res =>{
					if(res.code==200){
						this.confirmLoading = true
						this.$message.success(this.form.id?'编辑成功':'新增成功')
						this.$refs.table.refresh(true)
						this.visible = false
						setTimeout(()=>{
							this.confirmLoading = false
							this.form.id = ''
							this.form.cover = ''
							this.form.url = ''
						},600)
					}
				})
			},
			handleCancel(){
				this.visible = false
			},
			handleChange(info) {
				const status = info.file.status;
				if (status !== 'uploading') {
					console.log(info.file, info.fileList);
				}
				if(status == "removed"){
				    this.form.cover = ''
				}
				if (status === 'done') {
                    if(info.file.response.code==0){
                        let enData = info.file.response.data;
                        //enData = decrypt(enData);
                        this.form.cover = enData.path;
                    }else if(info.file.response.code==-98){
                        this.$message.error(`登录已过期,请重新登录`);
                        setTimeout(_=>{
                            localStorage.clear();
                            location.reload();
                        },1200)
                    }
				} else if (status === 'error') {
				    this.$message.error(`${info.file.name} 上传失败.`);
				}
			},
			deleteImg(){  //删除上传图片
				this.form.cover = ''
			}
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>


<style scoped>
.bannerItem{
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.bannerItem_l{
	min-width:100px;
}
.bannerItem_r{
	min-width: 1px;
	width: 100%;
}
.bannerItem_tip{
	font-size:14px;
	font-family:PingFangSC-Regular,PingFang SC;
	font-weight:400;
	color:rgba(153,153,153,1);
	margin-top: 12px;
}
.tips{
	font-style: normal;
	color: #FF3B30;
}
.bannerUpload{
	width:375px;
	height:88px;
	background:rgba(245,245,245,1);
	border-radius:4px;
	border:1px solid rgba(221,221,221,1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.preview{
	position: relative;
	z-index: 0;
}
.preview img{
	width: 100%;
	border-radius: 2px;
	cursor: pointer;
}
.icon-delete{
	color:#FF3B30;
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 20px;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
}
</style>